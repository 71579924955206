import React from 'react';
import { Box, Heading, Text, VStack, Avatar, Flex } from '@chakra-ui/react';

const Testimonial = ({ name, text, image }) => (
  <Box borderWidth="1px" borderRadius="lg" p={4}>
    <Flex align="center" mb={4}>
      <Avatar src={image} mr={4} />
      <Heading size="md">{name}</Heading>
    </Flex>
    <Text>{text}</Text>
  </Box>
);

const Testimonials = () => {
  const testimonials = [
    { name: "Carlos", text: "Incredible experience. The girls are beautiful and very friendly.", image: "/avatar1.jpg" },
    { name: "Juan", text: "I love the variety of girls and how easy it is to use the platform.", image: "/avatar2.jpg" },
    { name: "Miguel", text: "The virtual dates are great. I feel like I'm really there.", image: "/avatar3.jpg" },
  ];

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        User Testimonials
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Discover what our users say about their experiences on Señorita.fun
      </Text>
      
      <VStack spacing={6}>
        {testimonials.map((t, index) => (
          <Testimonial key={index} name={t.name} text={t.text} image={t.image} />
        ))}
      </VStack>
    </Box>
  );
};

export default Testimonials;