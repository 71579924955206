import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import LatinGirls from './components/LatinGirls';
import FreeEroticChat from './components/FreeEroticChat';
import LiveWebcam from './components/LiveWebcam';
import VirtualDating from './components/VirtualDating';
import GirlsByCountry from './components/GirlsByCountry';
import HowItWorks from './components/HowItWorks';
import Testimonials from './components/Testimonials';
import Blog from './components/Blog';
import Profile from './components/Profile';
import Policy from './components/Policy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/FreeEroticChat" element={<FreeEroticChat />} />
        <Route path="/LatinGirls" element={<LatinGirls />} />
        <Route path="/GirlsByCountry" element={<GirlsByCountry />} />
        <Route path="/VirtualDating" element={<VirtualDating />} />
        <Route path="/HowItWorks" element={<HowItWorks />} />
        <Route path="/LiveWebcam" element={<LiveWebcam />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Policy" element={<Policy />} />
      </Routes>
    </Router>
  );
}

export default App;
