import React from 'react';
import { Box, Heading, Text, SimpleGrid, Button, useToast } from '@chakra-ui/react';
import { handleClick, TELEGRAM_BOT_URL } from './Home';

const GirlsByCountry = () => {
  const countries = ['Colombia', 'Venezuela', 'Argentina', 'Brazil', 'Mexico', 'Peru'];
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Girls by Country
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Explore the diversity and beauty of Latin girls by country. Each nation has its unique charm.
      </Text>
      
      <SimpleGrid columns={[2, 3, 3]} spacing="20px" mb="10">
        {countries.map((country, index) => (
          <Button
          key={index}
          colorScheme="pink"
          size="lg"
          as="a"
          href={TELEGRAM_BOT_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClick(toast)}>
            Girls from {country}
          </Button>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default GirlsByCountry;