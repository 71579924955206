import { Box, Text, Flex, Heading, Image, Button, SimpleGrid, VStack, Fade, ScaleFade, useToast, Popover, PopoverContent, PopoverHeader, PopoverBody, PopoverFooter, PopoverArrow, PopoverCloseButton, Tooltip } from '@chakra-ui/react';
import { BsHeart, BsChat, BsFire, BsGift } from "react-icons/bs";
import { SiTelegram } from 'react-icons/si';
import { sendLandingPageViewed, sendStartChatClicked } from '../telemetry';
import { useEffect, useState } from 'react';

const backgroundColor1 = "radial-gradient(circle, rgba(20, 0, 20, 0.9) 0%, black 100%)";
const backgroundColor2 = "linear-gradient(0deg, rgba(50, 0, 50, 0.8) 0%, rgba(0, 0, 0, 0.9) 100%)";

const START_CHATTING = "Get FREE Show Now!";

export const TELEGRAM_BOT_URL = "https://t.me/DreamGFXXXBot?start=thefxxk";

export const handleClick = (toast) => {
  sendStartChatClicked();
  toast({
    title: "Exclusive Offer Activated!",
    description: "Your free private show is waiting. Don't miss out!",
    status: "success",
    duration: 5000,
    isClosable: true,
  });
};

const girls = [
  { name: "Maria", age: 24, description: "Wild and ready to please", image: "/girl1.png" },
  { name: "Sofia", age: 22, description: "Naughty and playful", image: "/girl2.png" },
  { name: "Valentina", age: 26, description: "Seductive dominatrix", image: "/girl3.png" },
  { name: "Camila", age: 23, description: "Kinky and adventurous", image: "/girl4.png" },
  { name: "Lucia", age: 25, description: "Sultry temptress", image: "/girl5.png" },
  { name: "Gabriela", age: 21, description: "Innocent but curious", image: "/girl6.png" },
  { name: "Isabella", age: 27, description: "Experienced and passionate", image: "/girl7.png" },
  { name: "Ana", age: 20, description: "Shy but eager to learn", image: "/girl8.png" },
  { name: "Paula", age: 28, description: "Dominant and demanding", image: "/girl9.png" },
  { name: "Daniela", age: 24, description: "Flexible and energetic", image: "/girl10.png" },
  { name: "Fernanda", age: 23, description: "Exotic dancer", image: "/girl11.png" },
  { name: "Catalina", age: 25, description: "Fetish enthusiast", image: "/girl12.png" },
  { name: "Elena", age: 22, description: "Sensual masseuse", image: "/girl13.png" },
  { name: "Julieta", age: 26, description: "Passionate lover", image: "/girl14.png" },
  { name: "Mariana", age: 24, description: "Flirty and fun", image: "/girl15.png" },
  { name: "Renata", age: 23, description: "Daring and bold", image: "/girl16.png" },
  { name: "Valeria", age: 25, description: "Seductive siren", image: "/girl17.png" },
  { name: "Ximena", age: 21, description: "Sweet and spicy", image: "/girl18.png" },
  { name: "Yolanda", age: 27, description: "Fiery and passionate", image: "/girl19.png" },
  { name: "Zoe", age: 22, description: "Playful tease", image: "/girl20.png" },
  { name: "Bianca", age: 26, description: "Alluring enchantress", image: "/girl21.png" },
  { name: "Carmen", age: 24, description: "Sultry dancer", image: "/girl22.png" },
  { name: "Diana", age: 23, description: "Mysterious and intriguing", image: "/girl23.png" },
  { name: "Esmeralda", age: 25, description: "Exotic beauty", image: "/girl24.png" },
];

function Home() {
  const [showContent, setShowContent] = useState(false);
  const [isAgeConfirmed, setIsAgeConfirmed] = useState(false);
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds
  const toast = useToast();

  useEffect(() => {
    sendLandingPageViewed();
    setShowContent(true);
    
    const timer = setTimeout(() => {
      toast({
        title: "🔥 Hot Deal Alert! 🔥",
        description: "Limited slots for free private shows. Claim yours now!",
        status: "error",
        duration: null,
        isClosable: true,
        position: "top",
      });
    }, 5000);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(countdownInterval);
    }
  }, [toast]);

  const handleAgeConfirmation = (confirmed) => {
    if (confirmed) {
      setIsAgeConfirmed(true);
    } else {
      window.location.href = "https://www.google.com";
    }
  };

  return (
    <Box
      minH="100vh"
      backgroundImage={`${backgroundColor1}, ${backgroundColor2}`}
      backgroundBlendMode="overlay"
      textAlign="center"
      fontSize={{ base: "sm", md: "md" }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        minH="100vh"
        flexDirection="column"
        padding={{ base: "10", md: "20" }}
      >
        {!isAgeConfirmed && (
          <Popover isOpen={true} onClose={() => {}} closeOnBlur={false}>
            <PopoverContent bg="rgba(0, 0, 0, 0.95)" borderColor="red.700" boxShadow="0 0 20px red">
              <PopoverHeader fontWeight="bold" color="red.400" fontSize="2xl">🔥 Enter the Forbidden Zone 🔥</PopoverHeader>
              <PopoverArrow bg="red.700" />
              <PopoverCloseButton color="red.400" />
              <PopoverBody color="white" fontSize="xl" textAlign="center">
                <Text mb={4}>You're about to experience pleasure beyond your wildest dreams.</Text>
                <Text fontWeight="bold">Are you brave enough to step into the fire?</Text>
              </PopoverBody>
              <PopoverFooter display="flex" justifyContent="space-between">
                <Button colorScheme="gray" onClick={() => handleAgeConfirmation(false)}>
                  I'm not ready
                </Button>
                <Button colorScheme="red" onClick={() => handleAgeConfirmation(true)} _hover={{ transform: "scale(1.05)", bg: "red.600" }}>
                  Yes, I'm 18+ and daring!
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        )}
        <Fade in={showContent} delay={0.2}>
          <Box
            borderRadius="full"
            px={{ base: 4, md: 8 }}
            py={{ base: 2, md: 4 }}
            boxShadow="0 0 20px rgba(255, 0, 0, 0.5)"
            backgroundColor="rgba(30, 0, 30, 0.9)"
            border="2px solid"
            borderColor="red.700"
            transition="all 0.3s"
            _hover={{ transform: "scale(1.05)", boxShadow: "0 0 30px rgba(255, 0, 0, 0.7)" }}
          >
            <Flex alignItems="center" justifyContent="center">
              <BsFire fontSize={{ base: "2xl", md: "3xl" }} color="red" />
              <Text
                fontSize={{ base: "xl", md: "2xl" }}
                color="red.400"
                mr={{ base: 2, md: 3 }}
                ml={{ base: 2, md: 3 }}
                fontWeight="bold"
              >
                TheFxxk.com
              </Text>
            </Flex>
          </Box>
        </Fade>
        <ScaleFade initialScale={0.9} in={showContent} delay={0.4}>
          <Heading
            color="red.400"
            mt={{ base: 6, md: 8 }}
            size={{ base: "2xl", md: "4xl" }}
            textShadow="2px 2px 4px rgba(255,0,0,0.5)"
          >
            Ignite Your Deepest Desires!
          </Heading>
        </ScaleFade>
        <Fade in={showContent} delay={0.6}>
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            color="gray.300"
            mt={{ base: 4, md: 6 }}
            mb={{ base: 4, md: 6 }}
          >
            Sultry sirens await to fulfill your every fantasy
          </Text>
        </Fade>
        <ScaleFade initialScale={0.9} in={showContent} delay={0.8}>
          <Tooltip label="Click now for your exclusive free show!" placement="top">
            <Button
              as="a"
              href={TELEGRAM_BOT_URL}
              target="_blank"
              rel="noopener noreferrer"
              bg="red.700"
              color="white"
              width={{ base: "280px", md: "400px" }}
              height={{ base: "80px", md: "120px" }}
              px={{ base: 6, md: 10 }}
              fontSize={{ base: "xl", md: "3xl" }}
              rightIcon={<SiTelegram />}
              leftIcon={<BsGift />}
              _hover={{
                bg: "red.800",
                transform: "translateY(-5px) scale(1.05)",
                boxShadow: "0 0 30px rgba(255, 0, 0, 0.7)",
              }}
              borderRadius="full"
              mb={{ base: "8", md: "16" }}
              onClick={() => handleClick(toast)}
              transition="all 0.3s"
            >
              {START_CHATTING}
            </Button>
          </Tooltip>
        </ScaleFade>
        <Text color="yellow.300" fontSize="xl" fontWeight="bold" mb={4}>
          Hurry! Offer expires in {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? '0' : ''}{countdown % 60}
        </Text>
        <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={10} mb="50px">
          {girls.map((girl, index) => (
            <ScaleFade initialScale={0.9} in={showContent} delay={0.2 * (index + 1)} key={index}>
              <Box 
                borderWidth="2px" 
                borderRadius="lg" 
                overflow="hidden" 
                bg="rgba(20, 0, 20, 0.9)"
                transition="all 0.3s"
                _hover={{
                  transform: "translateY(-10px) scale(1.05)",
                  boxShadow: "0 0 20px rgba(255, 0, 0, 0.5)",
                }}
              >
                <Image src={girl.image} alt={girl.name} height="250px" width="100%" objectFit="cover" />
                <VStack p="4" align="start">
                  <Heading as="h3" size="md" color="red.400">{girl.name}</Heading>
                  <Text fontSize="sm" color="gray.300">{girl.age} years old</Text>
                  <Text fontSize="xs" color="gray.400">{girl.description}</Text>
                  <Flex justify="space-between" width="100%" mt="3">
                    <Button
                      as="a"
                      href={TELEGRAM_BOT_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      leftIcon={<BsHeart />}
                      size="sm"
                      variant="outline"
                      colorScheme="red"
                      onClick={() => handleClick(toast)}
                      _hover={{ bg: "red.700", transform: "scale(1.05)" }}
                    >
                      Like
                    </Button>
                    <Button
                      as="a"
                      href={TELEGRAM_BOT_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      leftIcon={<BsChat />}
                      size="sm"
                      variant="solid"
                      colorScheme="red"
                      onClick={() => handleClick(toast)}
                      _hover={{ bg: "red.600", transform: "scale(1.05)" }}
                    >
                      Chat Now
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
}

export default Home;