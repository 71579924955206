import React from 'react';
import { Box, Heading, Text, VStack, UnorderedList, ListItem } from '@chakra-ui/react';

const Policy = () => {
  return (
    <Box maxWidth="800px" margin="auto" padding="20px">
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="2xl" textAlign="center">
          TheFxxk.com Policies
        </Heading>
        
        <Box>
          <Heading size="lg" mb={2}>Privacy Policy</Heading>
          <Text>At TheFxxk.com, we value and protect your privacy. Our policy includes:</Text>
          <UnorderedList pl={4} mt={2}>
            <ListItem>Protection of personal data</ListItem>
            <ListItem>No sharing of information with third parties</ListItem>
            <ListItem>Control options over your information</ListItem>
          </UnorderedList>
        </Box>
        
        <Box>
          <Heading size="lg" mb={2}>Terms of Service</Heading>
          <Text>By using our service, you agree to comply with our terms, which include:</Text>
          <UnorderedList pl={4} mt={2}>
            <ListItem>Being over 18 years old</ListItem>
            <ListItem>Not using offensive or abusive language</ListItem>
            <ListItem>Respecting other users and models</ListItem>
          </UnorderedList>
        </Box>
        
        <Box>
          <Heading size="lg" mb={2}>Cookie Policy</Heading>
          <Text>We use cookies to improve your experience on our site. This allows us to:</Text>
          <UnorderedList pl={4} mt={2}>
            <ListItem>Remember your preferences</ListItem>
            <ListItem>Offer personalized content</ListItem>
            <ListItem>Analyze site traffic</ListItem>
          </UnorderedList>
        </Box>
      </VStack>
    </Box>
  );
};

export default Policy;