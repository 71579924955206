import React from 'react';
import { Box, Heading, Text, VStack, OrderedList, ListItem } from '@chakra-ui/react';

const HowItWorks = () => {
  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        How TheFxxk.com Works
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Discover how easy it is to connect with beautiful Latin girls on our platform.
      </Text>
      
      <VStack spacing="6" align="stretch">
        <OrderedList spacing={4}>
          <ListItem>Register for free on our platform</ListItem>
          <ListItem>Explore profiles of Latin girls</ListItem>
          <ListItem>Start a chat or video call</ListItem>
          <ListItem>Enjoy unique interactions and experiences</ListItem>
        </OrderedList>
      </VStack>
    </Box>
  );
};

export default HowItWorks;