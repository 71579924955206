import React from 'react';
import { Box, Heading, Text, VStack, Image, Button } from '@chakra-ui/react';

const Profile = () => {
  return (
    <Box maxWidth="800px" margin="auto" padding="20px">
      <VStack spacing={6} align="stretch">
        <Image src="/profile-pic.jpg" alt="Profile picture" borderRadius="full" boxSize="200px" alignSelf="center" />
        
        <Heading as="h1" size="2xl" textAlign="center">
          Your Profile
        </Heading>
        
        <Box>
          <Heading size="md" mb={2}>Personal Information</Heading>
          <Text>Name: John Smith</Text>
          <Text>Age: 28</Text>
          <Text>Location: Mexico City</Text>
        </Box>
        
        <Box>
          <Heading size="md" mb={2}>Preferences</Heading>
          <Text>Interests: Latin girls, live webcam</Text>
          <Text>Languages: Spanish, English</Text>
        </Box>
        
        <Button colorScheme="pink">Edit Profile</Button>
      </VStack>
    </Box>
  );
};

export default Profile;