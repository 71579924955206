import React from 'react';
import { Box, Heading, Text, Button, VStack, useToast } from '@chakra-ui/react';
import { handleClick, TELEGRAM_BOT_URL } from './Home';

const FreeEroticChat = () => {
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Free Erotic Chat with Latin Girls
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Enjoy spicy and sensual conversations with the hottest girls from Latin America, completely free!
      </Text>
      
      <VStack spacing="6" align="stretch">
        <Text fontSize="lg">
          Our free erotic chat offers you:
        </Text>
        <Box>
          <Text fontSize="lg" as="ul" pl="6">
            <li>Instant access without registration</li>
            <li>Private chats with beautiful girls</li>
            <li>Live video options</li>
            <li>Variety of themed rooms</li>
          </Text>
        </Box>
      </VStack>
      
      <Box textAlign="center" mt="10">
        <Button
          colorScheme="pink"
          size="lg"
          as="a"
          href={TELEGRAM_BOT_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClick(toast)}
        >
          Start Free Chat on Telegram
        </Button>
      </Box>
    </Box>
  );
};

export default FreeEroticChat;