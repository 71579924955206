import React from 'react';
import { Box, Heading, Text, SimpleGrid, Image, Button, useToast } from '@chakra-ui/react';
import { handleClick, TELEGRAM_BOT_URL } from './Home';

const LiveWebcam = () => {
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Live Webcam with Sexy Latin Girls
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Experience the thrill of watching and interacting with beautiful Latin girls in real-time through our live webcams.
      </Text>
      
      <SimpleGrid columns={[1, 2, 3]} spacing="20px" mb="10">
        <Image src="/girl1.png" alt="Girl on webcam" borderRadius="md" />
        <Image src="/girl2.png" alt="Live model" borderRadius="md" />
        <Image src="/girl3.png" alt="Live stream" borderRadius="md" />
      </SimpleGrid>
      
      <Box textAlign="center" mt="10">
        <Button colorScheme="pink" size="lg"
        as="a"
        href={TELEGRAM_BOT_URL}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleClick(toast)}>
          Watch Live Webcams
        </Button>
      </Box>
    </Box>
  );
};

export default LiveWebcam;