import amplitude from 'amplitude-js';

export const WEB_SOURCE = "THEFXXK.COM";

export const eventType = {
  LANDING_PAGE_VIEWED: WEB_SOURCE + "_" + "LANDING_PAGE_VIEWED",
  START_CHATTING_CLICKED: WEB_SOURCE + "_" + "START_CHAT_CLICKED",
}

export const sendLandingPageViewed = () => {
  amplitude.getInstance().logEvent(eventType.LANDING_PAGE_VIEWED, {});
}

export const sendStartChatClicked = () => {
  amplitude.getInstance().logEvent(eventType.START_CHATTING_CLICKED, {});
}

export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
}


